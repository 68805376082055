.index {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 50px 15px 0;
  display: block;
}
.before-steps {
  padding-bottom: 40px;
}

.image-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
}

.image-container img {
  max-width: 100%; /* Ensure images are initially at most 100% of their container */
}

/* Media query for smaller screens */
@media (max-width: 800px) {
  .image-container img {
    max-width: 40%; /* Reduce the max-width for smaller screens */
  }
}

.index .dzie-dobry-w-dniu {
  color: #000000;
  font-family: "IBM Plex Sans", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  padding-top: 20px;
}

.index .p {
  padding: 20px 0;
  color: #000000;
  font-family: "IBM Plex Sans", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}
//---------------------------

.steps * {
  word-wrap: break-word;
}

.index h2 {
  color: #000000;
  font-family: "IBM Plex Mono", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  padding-bottom: 30px;
}

.index .step {
  position: relative;
}

.index .group-1 {
  height: 253px;
  width: 226px;
}

.index .rectangle-2 {
  background-color: #ffe500;
  height: 133px;
  left: 0;
  position: absolute;
  top: 0;
  width: 133px;
}

.index .text-wrapper-2 {
  color: #000000;
  font-family: "IBM Plex Mono", Helvetica;
  font-size: 60px;
  font-weight: 700;
  left: 47px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 26px;
  width: 39px;
}

.index .front-desk {
  height: 167px;
  left: 58px;
  object-fit: cover;
  position: absolute;
  top: 86px;
  width: 167px;
}

.index .ka-da-karta-posiada {
  color: #000000;
  font-family: "IBM Plex Mono", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.index .step-2 {
  position: relative;
}

.index .group-2 {
  height: 253px;
  width: 234px;
}

.index .text-wrapper-4 {
  color: #000000;
  font-family: "IBM Plex Mono", Helvetica;
  font-size: 70px;
  font-weight: 700;
  left: 47px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 26px;
  white-space: nowrap;
  width: 39px;
}

.index .smartphone {
  height: 167px;
  left: 67px;
  object-fit: cover;
  position: absolute;
  top: 86px;
  width: 167px;
}

.index .w-poczekalni-na {
  color: #000000;
  font-family: "IBM Plex Mono", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.index .step-3 {
  position: relative;
}

.index .group-3 {
  height: 253px;
  width: 242px;
}

.index .sakura {
  height: 167px;
  left: 75px;
  object-fit: cover;
  position: absolute;
  top: 86px;
  width: 167px;
}

.index .przypisuj-c-numer {
  color: #000000;
  font-family: "IBM Plex Mono", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.index .step-4 {
  position: relative;
}

.index .group-4 {
  height: 253px;
  width: 250px;
}

.index .pediatrician {
  height: 167px;
  left: 83px;
  object-fit: cover;
  position: absolute;
  top: 86px;
  width: 167px;
}

.index .gdy-numer-twojej {
  color: #000000;
  font-family: "IBM Plex Mono", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.image-container.bottom-img {
  padding: 0 60px 0;
  display: flex;
}

.wrap {
  padding-bottom: 40px;
  display: flex;
  flex-shrink: initial;
}

.text-group {
  flex: 2;
}

.overlap {
  flex: 1;
}

@media only screen and (max-width: 768px) {
  .index h2 {
    font-size: larger !important;
  }

  .index p {
    font-size: medium !important;
  }

  .index .overlap {
    padding-bottom: 40px;
  }
  .wrap {
    flex-wrap: wrap;
  }
  .text-group {
    flex: unset;
  }

  .overlap {
    flex: unset;
    width: 100% !important;
  }
}

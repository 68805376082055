.blinking-element-enter-active,
.blinking-element-active {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
  .view-type .queue-item-icon-wrapper{
    width: 72px;
  }
  .view-type .queue-item-icon{
    width: 80px !important;
    height: 72px !important;
  }
  .view-type.queue-item{
    font-size: xxx-large !important;
  }


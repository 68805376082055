$navyBlue100: #1f4d93;
$darkBlue100: #0a1331;
$ocean100: #005a76;
$gunPowder: #1f1f20;
$blueTrim: #d7eef3;
$softGray: #eef6fa;
$softBlue: #f6fafc;

$inert: #cccccc;
$neutral: #ececec;
$textfieldGray: #f4f4f4;
$ceramic: #f8f8f8;
$white: #ffffff;

$redPrompt: #bc2f2f;
$redAlert: #ea746c;
$redAccent: #f3d9d9;

$greenPrompt: #397c28;
$greenAlert: #5fa24f;
$greenAccent: #cef2cb;

$maybeYellow: #f6db94;

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;700;800;900&display=swap");

@function a($x) {
  $base: 8;
  @return ($base * $x) + px;
}

@mixin roundBorder {
  border-radius: a(0.75);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInMenu {
  from {
    transform: translateX(100%);
    //opacity: 0;
  }
  to {
    transform: translateX(0%);
    //opacity: 1;
  }
}

@keyframes slideOutMenu {
  from {
    transform: translateX(0%);
    //opacity: 1;
  }
  to {
    transform: translateX(100%);
    //opacity: 0;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

$font: "Lato", "Source Sans Pro", "Segoe UI", "Roboto", sans-serif;
//$font: "Source Sans Pro", sans-serif;

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: $font;
  color: $gunPowder;
  background-color: $softBlue;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
}

html,
body,
#root,
.App {
  min-height: 100vh;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: $gunPowder;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

.rnc__notification-container--top-right {
  top: 85px;
}

.bg-w {
  background-color: $white;
}

.mb-3 {
  margin-bottom: a(3);
}

.ta-c {
  text-align: center;
}
.ta-l {
  text-align: left;
}
.ta-r {
  text-align: right;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-v-center {
  display: flex;
  justify-content: center;
}
h1,
h2 {
  line-height: 1.2;
}
.def-div {
  background-color: $softBlue;
  padding: a(5);
  @include roundBorder();
  & > * {
    margin-bottom: a(2);
  }
}

h1,
h2 {
  letter-spacing: -2%;
  line-height: 1.2;
}

h4 {
  letter-spacing: 2%;
}

h1,
h2,
h3 {
  font-weight: 900;
}

h4,
em,
button {
  font-weight: 700;
}
.btn-primary:hover {
  background-color: #1f4d93f7;
  transform: scale(1.05);
}

input.error,
.error > input {
  border: solid 2px #ff2b3f;
}

label {
  font-weight: 600;
}

input,
select {
  background-color: $ceramic;
  border: solid 2px $gunPowder;
  @include roundBorder();
  padding: a(1);
  margin-bottom: a(2);
  margin-top: a(0.5);
  width: calc(100% - a(2));
  font-family: $font;
  &:active,
  &:focus {
    background-color: $white;
    outline: solid 2px rgba($gunPowder, 0.5);
  }
}
select {
  display: block;
  width: 100%;
  margin-right: a(3);
}

button {
  //letter-spacing: 5%;
  letter-spacing: 1px;
  font-size: 1em;
  border: none;
}

.top-header {
  display: flex;
  position: fixed;
  z-index: 420;
  top: 0;
  width: calc(100vw - a(4));
  height: a(5);
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  padding: a(2);
  h1 {
    font-family: "Garamond", serif;
    font-weight: 700;
  }
}

.top-header > select.language-picker-select {
  width: 110px;
  text-align: left;
  cursor: pointer;
  z-index: 50;
  position: absolute;
  top: 20px;
  right: 20px;
  &:hover {
    .lang-dropdown {
      display: block;
    }
  }
}
.lang-dropdown {
  width: 46px;
  margin-right: 20px;
  margin-left: auto;
}
.lang-flag {
  width: 20px;
  display: inline-block;
}
.sound-toggle-icon {
  width: 46px;
  height: 46px;
  margin-right: 20px;
  margin-left: auto;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}

.top-header + main {
  padding-top: a(9);
}
main {
  flex: 1;
  //margin: a(9) auto 0;
  margin: 0 auto 0;
  padding: 0 a(2) 0;
  min-height: calc(100vh - a(5) - a(4) - a(4) - 24px);
  max-width: 1024px;
  & > * {
    opacity: 1;
    animation: fadeIn 350ms ease-in;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

button,
.btn {
  display: inline-block;
  font-family: $font;
  text-align: center;
}

.btn {
  display: inline-block;
  text-transform: uppercase;
  padding: a(2) a(4);
  @include roundBorder();
  font-size: 1.2em;
  &-primary {
    background-color: $navyBlue100;
    color: $white;
    border: 1px solid $navyBlue100;
  }
  &-secondary {
    background-color: $white;
    color: $navyBlue100;
    border: 1px solid $navyBlue100;
  }
  &-tertiary {
    background-color: $white;
    color: $gunPowder;
    border: 1px solid $gunPowder;
  }
  &-link {
    background-color: $white;
    color: $navyBlue100;
    border: 1px solid $white;
    &:hover {
      border: 1px solid $navyBlue100;
      text-decoration: underline;
    }
  }
}

.btn-admin {
  display: inline-block;
  margin: a(1);
  padding: a(1);
  @include roundBorder();
  &-edit {
    background-color: $greenPrompt;
  }
  &-delete {
    background-color: $redPrompt;
  }
}

.button-link {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

.button-link:hover {
  background-color: #eee;
}

.button-link::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
}

.stats-container {
  width: 100%;
  text-align: center;
}

.stats-container > h2 {
  padding: 8px;
}

.chrtPlots {
  padding-top: 20px;
}

.chrtPlots > p {
  text-decoration: underline;
}

.stats-sum-hostpital {
  text-align: left;
  background: #1f4d93f7;
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.w100 {
  width: 100%;
}

.queue-element {
  flex-direction: column;
  width: 100%;
}

.queue-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: a(2) a(3);
  margin: a(1) 0;
  &.first-item {
    background-color: #5cc1cd;
    color: $ceramic;
  }
  * {
    margin-bottom: 0;
  }
  @include roundBorder();
  border: solid 2px $blueTrim;
  &:hover,
  &-active {
    border: solid 2px rgba($ocean100, 0.5);
  }
  &-left {
    display: flex;
    align-items: center;
    div {
      margin-right: a(3);
    }
  }
  &-id {
    margin-left: a(1);
  }
  &-number {
    border-right: solid 2px $blueTrim;
    padding-right: a(2);
  }
  &-icon {
    width: a(5);
    display: block;
    height: a(4);
    margin: 0 auto;
    align-content: center;
  }
  &-icon-wrapper {
    display: flex;
    text-align: center;
    margin-right: a(1);
    width: a(4);
  }
}

.queue-item.my-card {
  background-color: $redAlert;
  color: $ceramic;
  &:hover {
    border: solid 2px $redPrompt;
  }
}

.in-cabinet {
  &.queue-container {
    margin-left: a(0);
    margin-right: a(0);
  }
  .queue-item {
    margin: a(2) a(-2) a(-4);
    background-color: #5cc1cd;
    color: $ceramic;
  }
}

.main-menu {
  margin-top: calc(a(4) + 32px);
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 50;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  transform: translateX(100%);
  background-color: $white;
  &-opened {
    transform: translateX(0);
    //opacity: 1;
    animation: slideInMenu 600ms ease-out;
  }
  &-closed {
    transform: translateX(100%);
    //opacity: 0;
    animation: slideOutMenu 700ms ease-in;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    li {
      margin: a(1);
    }
  }
}

.clinic-container {
  display: flex;
  flex-direction: column;
}

.home-container {
  margin: 0 auto;
  max-width: 768px;
  input {
    font-size: 2em;
    text-align: center;
    font-weight: 700;
    label {
      color: $navyBlue100;
      font-weight: 500;
    }
    input {
      display: flex;
      font-size: 2em;
      text-align: center;
      font-weight: 700;
      margin-bottom: a(4);
    }
    h1 {
      margin-bottom: a(5);
    }
    p {
      margin-bottom: a(4);
    }
  }
}

.queue-head-container {
  @include roundBorder();
  border: solid 2px $gunPowder;
  padding: a(2);
  margin: a(2);
  > h1,
  > h2 {
    margin-left: a(6);
  }
}

.queue-container {
  margin: a(2);
  min-height: 45vh;
}

.clinic-item {
  padding: a(1);
  margin: a(2);
  h2,
  h3 {
    text-align: center;
  }
  h2 {
    margin-bottom: a(2);
  }
  & .queue-item:first-child {
    background-color: #5cc1cd;
    color: $ceramic;
  }
}

.all-cards-container {
  display: flex;
  flex-wrap: wrap;
}

.card-item {
  min-width: 33vh;
  text-align: center;
  border: solid 3px $gunPowder;
  @include roundBorder();
  margin: a(3);
  h1,
  h2 {
    margin-bottom: a(1);
  }
}

.admin-panel-card {
  margin: a(3);
  h2 {
    margin-bottom: a(2);
  }
  .btn {
    margin: a(1);
  }
}

.instruction {
  &-container {
    padding: a(2);
  }
  &-banner {
    width: 100%;
  }
}

.notifications-banner {
  background-color: $ceramic;
  position: fixed;
  bottom: a(0.1);
  z-index: 2137;
  width: 100vw;
  left: 0;
  padding: a(2) a(5);
  text-align: center;
}

.PhoneInputInput {
  margin-bottom: 0 !important;
}
.PhoneInput {
  margin-bottom: a(2) !important;
}

footer {
  margin: a(1) 0;
  a {
    margin: 0 a(1);
  }
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
  position: relative;
  display: flex;
  width: 100%;
  height: 24px;
  padding-top: a(2);
}

.footer-text {
  flex-grow: 1;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.footer-links {
  position: absolute;
  right: 0;
}

.footer-links a {
  text-decoration: none;
  margin: 0 10px;
}

@media (max-width: 768px) {
  .footer-links {
    bottom: 30px;
  }
  .menage-buttons {
    flex: none;
    order: 1;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .clinic-container {
    display: flex;
    flex-direction: row;
  }
  .main-menu {
    width: 40vw;
  }
}

@media (min-width: 1024px) {
  .clinic-item {
    min-width: 40vw;
  }
}
